// source: users_entity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var leagues_entity_pb = require('./leagues_entity_pb.js');
goog.object.extend(proto, leagues_entity_pb);
var karaoke_entity_pb = require('./karaoke_entity_pb.js');
goog.object.extend(proto, karaoke_entity_pb);
var master_violation_entity_pb = require('./master/violation_entity_pb.js');
goog.object.extend(proto, master_violation_entity_pb);
var photo_entity_pb = require('./photo_entity_pb.js');
goog.object.extend(proto, photo_entity_pb);
goog.exportSymbol('proto.AcquireDiamondCondition', null, global);
goog.exportSymbol('proto.AcquiredDiamondDetail', null, global);
goog.exportSymbol('proto.AvailableClosingTime', null, global);
goog.exportSymbol('proto.Ban', null, global);
goog.exportSymbol('proto.Blocking', null, global);
goog.exportSymbol('proto.DayOfLiverTerm', null, global);
goog.exportSymbol('proto.Device', null, global);
goog.exportSymbol('proto.FanType', null, global);
goog.exportSymbol('proto.Follower', null, global);
goog.exportSymbol('proto.Following', null, global);
goog.exportSymbol('proto.Gender', null, global);
goog.exportSymbol('proto.ListenerMembershipInfo', null, global);
goog.exportSymbol('proto.LiverRequestedSongHistory', null, global);
goog.exportSymbol('proto.Moderator', null, global);
goog.exportSymbol('proto.MonthlyStatisticsPageKey', null, global);
goog.exportSymbol('proto.NotificationType', null, global);
goog.exportSymbol('proto.PearlCup', null, global);
goog.exportSymbol('proto.PearlCupDivision', null, global);
goog.exportSymbol('proto.PearlCupDivisionHoldingStatus', null, global);
goog.exportSymbol('proto.PearlCupDivisionWithPrize', null, global);
goog.exportSymbol('proto.PearlCupPrize', null, global);
goog.exportSymbol('proto.PearlCupPrize.Condition', null, global);
goog.exportSymbol('proto.PearlCupPrize.Condition.Amount', null, global);
goog.exportSymbol('proto.PearlCupPrizeItem', null, global);
goog.exportSymbol('proto.Prefecture', null, global);
goog.exportSymbol('proto.RankingLiver', null, global);
goog.exportSymbol('proto.RankingTerm', null, global);
goog.exportSymbol('proto.RankingTermType', null, global);
goog.exportSymbol('proto.RequestStoreReviewDisplayState', null, global);
goog.exportSymbol('proto.SearchUser', null, global);
goog.exportSymbol('proto.StatisticsRecord', null, global);
goog.exportSymbol('proto.Town', null, global);
goog.exportSymbol('proto.TwitterAccount', null, global);
goog.exportSymbol('proto.UnreadNotificationInfo', null, global);
goog.exportSymbol('proto.User', null, global);
goog.exportSymbol('proto.UserBanHistory', null, global);
goog.exportSymbol('proto.UserBirthday', null, global);
goog.exportSymbol('proto.UserBlock', null, global);
goog.exportSymbol('proto.UserDevice', null, global);
goog.exportSymbol('proto.UserFlag', null, global);
goog.exportSymbol('proto.UserFollow', null, global);
goog.exportSymbol('proto.UserFollowCount', null, global);
goog.exportSymbol('proto.UserItem', null, global);
goog.exportSymbol('proto.UserItemWithFavePoint', null, global);
goog.exportSymbol('proto.UserItemWithFollow', null, global);
goog.exportSymbol('proto.UserLiveClosingTime', null, global);
goog.exportSymbol('proto.UserProfile', null, global);
goog.exportSymbol('proto.UserProfileScreening', null, global);
goog.exportSymbol('proto.UserWithdraw', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.User.displayName = 'proto.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UserItem.repeatedFields_, null);
};
goog.inherits(proto.UserItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserItem.displayName = 'proto.UserItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserProfile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserProfile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserProfile.displayName = 'proto.UserProfile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserBirthday = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserBirthday, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserBirthday.displayName = 'proto.UserBirthday';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserFlag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserFlag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserFlag.displayName = 'proto.UserFlag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserFollow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserFollow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserFollow.displayName = 'proto.UserFollow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserFollowCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserFollowCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserFollowCount.displayName = 'proto.UserFollowCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserBlock = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserBlock, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserBlock.displayName = 'proto.UserBlock';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserWithdraw = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserWithdraw, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserWithdraw.displayName = 'proto.UserWithdraw';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Prefecture = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Prefecture, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Prefecture.displayName = 'proto.Prefecture';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Town = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Town, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Town.displayName = 'proto.Town';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TwitterAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TwitterAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TwitterAccount.displayName = 'proto.TwitterAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Following = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Following, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Following.displayName = 'proto.Following';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Follower = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Follower, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Follower.displayName = 'proto.Follower';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Blocking = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Blocking, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Blocking.displayName = 'proto.Blocking';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserBanHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UserBanHistory.repeatedFields_, null);
};
goog.inherits(proto.UserBanHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserBanHistory.displayName = 'proto.UserBanHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Ban = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Ban, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Ban.displayName = 'proto.Ban';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserProfileScreening = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.UserProfileScreening.repeatedFields_, null);
};
goog.inherits(proto.UserProfileScreening, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserProfileScreening.displayName = 'proto.UserProfileScreening';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserItemWithFollow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserItemWithFollow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserItemWithFollow.displayName = 'proto.UserItemWithFollow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SearchUser = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SearchUser, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SearchUser.displayName = 'proto.SearchUser';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserItemWithFavePoint = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserItemWithFavePoint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserItemWithFavePoint.displayName = 'proto.UserItemWithFavePoint';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RankingLiver = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RankingLiver, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RankingLiver.displayName = 'proto.RankingLiver';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RankingTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RankingTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RankingTerm.displayName = 'proto.RankingTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UnreadNotificationInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UnreadNotificationInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UnreadNotificationInfo.displayName = 'proto.UnreadNotificationInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AcquireDiamondCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AcquireDiamondCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AcquireDiamondCondition.displayName = 'proto.AcquireDiamondCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.MonthlyStatisticsPageKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.MonthlyStatisticsPageKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.MonthlyStatisticsPageKey.displayName = 'proto.MonthlyStatisticsPageKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AcquiredDiamondDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AcquiredDiamondDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AcquiredDiamondDetail.displayName = 'proto.AcquiredDiamondDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StatisticsRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StatisticsRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StatisticsRecord.displayName = 'proto.StatisticsRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DayOfLiverTerm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DayOfLiverTerm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DayOfLiverTerm.displayName = 'proto.DayOfLiverTerm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AvailableClosingTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.AvailableClosingTime.repeatedFields_, null);
};
goog.inherits(proto.AvailableClosingTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AvailableClosingTime.displayName = 'proto.AvailableClosingTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserLiveClosingTime = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserLiveClosingTime, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserLiveClosingTime.displayName = 'proto.UserLiveClosingTime';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PearlCup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCup.displayName = 'proto.PearlCup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCupDivision = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PearlCupDivision, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCupDivision.displayName = 'proto.PearlCupDivision';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCupDivisionWithPrize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PearlCupDivisionWithPrize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCupDivisionWithPrize.displayName = 'proto.PearlCupDivisionWithPrize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCupPrize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PearlCupPrize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCupPrize.displayName = 'proto.PearlCupPrize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCupPrize.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.PearlCupPrize.Condition.repeatedFields_, null);
};
goog.inherits(proto.PearlCupPrize.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCupPrize.Condition.displayName = 'proto.PearlCupPrize.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCupPrize.Condition.Amount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PearlCupPrize.Condition.Amount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCupPrize.Condition.Amount.displayName = 'proto.PearlCupPrize.Condition.Amount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PearlCupPrizeItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PearlCupPrizeItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PearlCupPrizeItem.displayName = 'proto.PearlCupPrizeItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserDevice.displayName = 'proto.UserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Device.displayName = 'proto.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LiverRequestedSongHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LiverRequestedSongHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LiverRequestedSongHistory.displayName = 'proto.LiverRequestedSongHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ListenerMembershipInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ListenerMembershipInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ListenerMembershipInfo.displayName = 'proto.ListenerMembershipInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Moderator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Moderator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Moderator.displayName = 'proto.Moderator';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.User.prototype.toObject = function(opt_includeInstance) {
  return proto.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    profileImageUrls: (f = msg.getProfileImageUrls()) && photo_entity_pb.ImageURL.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    displayUserId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.User}
 */
proto.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.User;
  return proto.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.User}
 */
proto.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = new photo_entity_pb.ImageURL;
      reader.readMessage(value,photo_entity_pb.ImageURL.deserializeBinaryFromReader);
      msg.setProfileImageUrls(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProfileImageUrls();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      photo_entity_pb.ImageURL.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getDisplayUserId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.User.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.User} returns this
 */
proto.User.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.User} returns this
 */
proto.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional jp.singcolor.ImageURL profile_image_urls = 4;
 * @return {?proto.jp.singcolor.ImageURL}
 */
proto.User.prototype.getProfileImageUrls = function() {
  return /** @type{?proto.jp.singcolor.ImageURL} */ (
    jspb.Message.getWrapperField(this, photo_entity_pb.ImageURL, 4));
};


/**
 * @param {?proto.jp.singcolor.ImageURL|undefined} value
 * @return {!proto.User} returns this
*/
proto.User.prototype.setProfileImageUrls = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.User} returns this
 */
proto.User.prototype.clearProfileImageUrls = function() {
  return this.setProfileImageUrls(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.User.prototype.hasProfileImageUrls = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.User.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.User} returns this
 */
proto.User.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.User.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.User} returns this
 */
proto.User.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string display_user_id = 7;
 * @return {string}
 */
proto.User.prototype.getDisplayUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.User} returns this
 */
proto.User.prototype.setDisplayUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UserItem.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserItem.prototype.toObject = function(opt_includeInstance) {
  return proto.UserItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.User.toObject(includeInstance, f),
    userProfile: (f = msg.getUserProfile()) && proto.UserProfile.toObject(includeInstance, f),
    leagueType: jspb.Message.getFieldWithDefault(msg, 4, 0),
    leagueTypeName: jspb.Message.getFieldWithDefault(msg, 11, ""),
    leagueId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    redioMode: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    singersList: jspb.Message.toObjectList(msg.getSingersList(),
    karaoke_entity_pb.Singer.toObject, includeInstance),
    closingTime: (f = msg.getClosingTime()) && proto.UserLiveClosingTime.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 9, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserItem}
 */
proto.UserItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserItem;
  return proto.UserItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserItem}
 */
proto.UserItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.User;
      reader.readMessage(value,proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = new proto.UserProfile;
      reader.readMessage(value,proto.UserProfile.deserializeBinaryFromReader);
      msg.setUserProfile(value);
      break;
    case 4:
      var value = /** @type {!proto.LeagueType} */ (reader.readEnum());
      msg.setLeagueType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeagueTypeName(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeagueId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRedioMode(value);
      break;
    case 7:
      var value = new karaoke_entity_pb.Singer;
      reader.readMessage(value,karaoke_entity_pb.Singer.deserializeBinaryFromReader);
      msg.addSingers(value);
      break;
    case 8:
      var value = new proto.UserLiveClosingTime;
      reader.readMessage(value,proto.UserLiveClosingTime.deserializeBinaryFromReader);
      msg.setClosingTime(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.User.serializeBinaryToWriter
    );
  }
  f = message.getUserProfile();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getLeagueType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getLeagueTypeName();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLeagueId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getRedioMode();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSingersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      karaoke_entity_pb.Singer.serializeBinaryToWriter
    );
  }
  f = message.getClosingTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.UserLiveClosingTime.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserItem.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.User}
 */
proto.UserItem.prototype.getUser = function() {
  return /** @type{?proto.User} */ (
    jspb.Message.getWrapperField(this, proto.User, 2));
};


/**
 * @param {?proto.User|undefined} value
 * @return {!proto.UserItem} returns this
*/
proto.UserItem.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserItem.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserProfile user_profile = 3;
 * @return {?proto.UserProfile}
 */
proto.UserItem.prototype.getUserProfile = function() {
  return /** @type{?proto.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.UserProfile, 3));
};


/**
 * @param {?proto.UserProfile|undefined} value
 * @return {!proto.UserItem} returns this
*/
proto.UserItem.prototype.setUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.clearUserProfile = function() {
  return this.setUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserItem.prototype.hasUserProfile = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional LeagueType league_type = 4;
 * @return {!proto.LeagueType}
 */
proto.UserItem.prototype.getLeagueType = function() {
  return /** @type {!proto.LeagueType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.LeagueType} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setLeagueType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string league_type_name = 11;
 * @return {string}
 */
proto.UserItem.prototype.getLeagueTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setLeagueTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 league_id = 5;
 * @return {number}
 */
proto.UserItem.prototype.getLeagueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setLeagueId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool redio_mode = 6;
 * @return {boolean}
 */
proto.UserItem.prototype.getRedioMode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setRedioMode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * repeated Singer singers = 7;
 * @return {!Array<!proto.Singer>}
 */
proto.UserItem.prototype.getSingersList = function() {
  return /** @type{!Array<!proto.Singer>} */ (
    jspb.Message.getRepeatedWrapperField(this, karaoke_entity_pb.Singer, 7));
};


/**
 * @param {!Array<!proto.Singer>} value
 * @return {!proto.UserItem} returns this
*/
proto.UserItem.prototype.setSingersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.Singer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Singer}
 */
proto.UserItem.prototype.addSingers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.Singer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.clearSingersList = function() {
  return this.setSingersList([]);
};


/**
 * optional UserLiveClosingTime closing_time = 8;
 * @return {?proto.UserLiveClosingTime}
 */
proto.UserItem.prototype.getClosingTime = function() {
  return /** @type{?proto.UserLiveClosingTime} */ (
    jspb.Message.getWrapperField(this, proto.UserLiveClosingTime, 8));
};


/**
 * @param {?proto.UserLiveClosingTime|undefined} value
 * @return {!proto.UserItem} returns this
*/
proto.UserItem.prototype.setClosingTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.clearClosingTime = function() {
  return this.setClosingTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserItem.prototype.hasClosingTime = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional int64 created_at = 9;
 * @return {number}
 */
proto.UserItem.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 updated_at = 10;
 * @return {number}
 */
proto.UserItem.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserItem} returns this
 */
proto.UserItem.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserProfile.prototype.toObject = function(opt_includeInstance) {
  return proto.UserProfile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserProfile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserProfile.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gender: jspb.Message.getFieldWithDefault(msg, 3, 0),
    url: jspb.Message.getFieldWithDefault(msg, 4, ""),
    twitterId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    twitterUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    twitterUrlForExternalBrowser: jspb.Message.getFieldWithDefault(msg, 20, ""),
    tiktokId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tiktokUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    youtubeHandle: jspb.Message.getFieldWithDefault(msg, 9, ""),
    youtubeUrl: jspb.Message.getFieldWithDefault(msg, 10, ""),
    instagramId: jspb.Message.getFieldWithDefault(msg, 11, ""),
    instagramUrl: jspb.Message.getFieldWithDefault(msg, 12, ""),
    prefecture: (f = msg.getPrefecture()) && proto.Prefecture.toObject(includeInstance, f),
    town: (f = msg.getTown()) && proto.Town.toObject(includeInstance, f),
    tag1: jspb.Message.getFieldWithDefault(msg, 15, ""),
    tag2: jspb.Message.getFieldWithDefault(msg, 16, ""),
    tag3: jspb.Message.getFieldWithDefault(msg, 17, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 18, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 19, 0),
    isGenderHidden: jspb.Message.getBooleanFieldWithDefault(msg, 21, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserProfile}
 */
proto.UserProfile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserProfile;
  return proto.UserProfile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserProfile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserProfile}
 */
proto.UserProfile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    case 3:
      var value = /** @type {!proto.Gender} */ (reader.readEnum());
      msg.setGender(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitterId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitterUrl(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitterUrlForExternalBrowser(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiktokId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiktokUrl(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubeHandle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setYoutubeUrl(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagramId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstagramUrl(value);
      break;
    case 13:
      var value = new proto.Prefecture;
      reader.readMessage(value,proto.Prefecture.deserializeBinaryFromReader);
      msg.setPrefecture(value);
      break;
    case 14:
      var value = new proto.Town;
      reader.readMessage(value,proto.Town.deserializeBinaryFromReader);
      msg.setTown(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag1(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag2(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTag3(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsGenderHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserProfile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserProfile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserProfile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserProfile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGender();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTwitterId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTwitterUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTwitterUrlForExternalBrowser();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTiktokId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTiktokUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getYoutubeHandle();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getYoutubeUrl();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getInstagramId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getInstagramUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPrefecture();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.Prefecture.serializeBinaryToWriter
    );
  }
  f = message.getTown();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.Town.serializeBinaryToWriter
    );
  }
  f = message.getTag1();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTag2();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getTag3();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getIsGenderHidden();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserProfile.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bio = 2;
 * @return {string}
 */
proto.UserProfile.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Gender gender = 3;
 * @return {!proto.Gender}
 */
proto.UserProfile.prototype.getGender = function() {
  return /** @type {!proto.Gender} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.Gender} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setGender = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string url = 4;
 * @return {string}
 */
proto.UserProfile.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string twitter_id = 5;
 * @return {string}
 */
proto.UserProfile.prototype.getTwitterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTwitterId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string twitter_url = 6;
 * @return {string}
 */
proto.UserProfile.prototype.getTwitterUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTwitterUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string twitter_url_for_external_browser = 20;
 * @return {string}
 */
proto.UserProfile.prototype.getTwitterUrlForExternalBrowser = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTwitterUrlForExternalBrowser = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string tiktok_id = 7;
 * @return {string}
 */
proto.UserProfile.prototype.getTiktokId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTiktokId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tiktok_url = 8;
 * @return {string}
 */
proto.UserProfile.prototype.getTiktokUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTiktokUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string youtube_handle = 9;
 * @return {string}
 */
proto.UserProfile.prototype.getYoutubeHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setYoutubeHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string youtube_url = 10;
 * @return {string}
 */
proto.UserProfile.prototype.getYoutubeUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setYoutubeUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string instagram_id = 11;
 * @return {string}
 */
proto.UserProfile.prototype.getInstagramId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setInstagramId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string instagram_url = 12;
 * @return {string}
 */
proto.UserProfile.prototype.getInstagramUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setInstagramUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Prefecture prefecture = 13;
 * @return {?proto.Prefecture}
 */
proto.UserProfile.prototype.getPrefecture = function() {
  return /** @type{?proto.Prefecture} */ (
    jspb.Message.getWrapperField(this, proto.Prefecture, 13));
};


/**
 * @param {?proto.Prefecture|undefined} value
 * @return {!proto.UserProfile} returns this
*/
proto.UserProfile.prototype.setPrefecture = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.clearPrefecture = function() {
  return this.setPrefecture(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserProfile.prototype.hasPrefecture = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Town town = 14;
 * @return {?proto.Town}
 */
proto.UserProfile.prototype.getTown = function() {
  return /** @type{?proto.Town} */ (
    jspb.Message.getWrapperField(this, proto.Town, 14));
};


/**
 * @param {?proto.Town|undefined} value
 * @return {!proto.UserProfile} returns this
*/
proto.UserProfile.prototype.setTown = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.clearTown = function() {
  return this.setTown(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserProfile.prototype.hasTown = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string tag1 = 15;
 * @return {string}
 */
proto.UserProfile.prototype.getTag1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTag1 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string tag2 = 16;
 * @return {string}
 */
proto.UserProfile.prototype.getTag2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTag2 = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string tag3 = 17;
 * @return {string}
 */
proto.UserProfile.prototype.getTag3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setTag3 = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional int64 created_at = 18;
 * @return {number}
 */
proto.UserProfile.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 updated_at = 19;
 * @return {number}
 */
proto.UserProfile.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional bool is_gender_hidden = 21;
 * @return {boolean}
 */
proto.UserProfile.prototype.getIsGenderHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserProfile} returns this
 */
proto.UserProfile.prototype.setIsGenderHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserBirthday.prototype.toObject = function(opt_includeInstance) {
  return proto.UserBirthday.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserBirthday} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserBirthday.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    birthday: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isMinor: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserBirthday}
 */
proto.UserBirthday.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserBirthday;
  return proto.UserBirthday.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserBirthday} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserBirthday}
 */
proto.UserBirthday.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAgeId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirthday(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMinor(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserBirthday.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserBirthday.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserBirthday} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserBirthday.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAgeId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getBirthday();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsMinor();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserBirthday.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserBirthday} returns this
 */
proto.UserBirthday.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 age_id = 2;
 * @return {number}
 */
proto.UserBirthday.prototype.getAgeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserBirthday} returns this
 */
proto.UserBirthday.prototype.setAgeId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string birthday = 3;
 * @return {string}
 */
proto.UserBirthday.prototype.getBirthday = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserBirthday} returns this
 */
proto.UserBirthday.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_minor = 4;
 * @return {boolean}
 */
proto.UserBirthday.prototype.getIsMinor = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserBirthday} returns this
 */
proto.UserBirthday.prototype.setIsMinor = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int64 created_at = 5;
 * @return {number}
 */
proto.UserBirthday.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserBirthday} returns this
 */
proto.UserBirthday.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 updated_at = 6;
 * @return {number}
 */
proto.UserBirthday.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserBirthday} returns this
 */
proto.UserBirthday.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserFlag.prototype.toObject = function(opt_includeInstance) {
  return proto.UserFlag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserFlag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserFlag.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isQa: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    isAntisocialForces: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    isCautionNeeded: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isPermittedToUseOrganization: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserFlag}
 */
proto.UserFlag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserFlag;
  return proto.UserFlag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserFlag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserFlag}
 */
proto.UserFlag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsQa(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAntisocialForces(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCautionNeeded(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPermittedToUseOrganization(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserFlag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserFlag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserFlag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserFlag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsQa();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getIsAntisocialForces();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIsCautionNeeded();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsPermittedToUseOrganization();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserFlag.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserFlag} returns this
 */
proto.UserFlag.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_qa = 4;
 * @return {boolean}
 */
proto.UserFlag.prototype.getIsQa = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserFlag} returns this
 */
proto.UserFlag.prototype.setIsQa = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool is_antisocial_forces = 5;
 * @return {boolean}
 */
proto.UserFlag.prototype.getIsAntisocialForces = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserFlag} returns this
 */
proto.UserFlag.prototype.setIsAntisocialForces = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool is_caution_needed = 6;
 * @return {boolean}
 */
proto.UserFlag.prototype.getIsCautionNeeded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserFlag} returns this
 */
proto.UserFlag.prototype.setIsCautionNeeded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_permitted_to_use_organization = 9;
 * @return {boolean}
 */
proto.UserFlag.prototype.getIsPermittedToUseOrganization = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserFlag} returns this
 */
proto.UserFlag.prototype.setIsPermittedToUseOrganization = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserFollow.prototype.toObject = function(opt_includeInstance) {
  return proto.UserFollow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserFollow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserFollow.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isFollowing: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isNotificationOn: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserFollow}
 */
proto.UserFollow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserFollow;
  return proto.UserFollow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserFollow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserFollow}
 */
proto.UserFollow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFollowing(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNotificationOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserFollow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserFollow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserFollow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserFollow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsFollowing();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsNotificationOn();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserFollow.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserFollow} returns this
 */
proto.UserFollow.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_following = 2;
 * @return {boolean}
 */
proto.UserFollow.prototype.getIsFollowing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserFollow} returns this
 */
proto.UserFollow.prototype.setIsFollowing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_notification_on = 3;
 * @return {boolean}
 */
proto.UserFollow.prototype.getIsNotificationOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserFollow} returns this
 */
proto.UserFollow.prototype.setIsNotificationOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserFollowCount.prototype.toObject = function(opt_includeInstance) {
  return proto.UserFollowCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserFollowCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserFollowCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    followingCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    followerCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserFollowCount}
 */
proto.UserFollowCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserFollowCount;
  return proto.UserFollowCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserFollowCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserFollowCount}
 */
proto.UserFollowCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFollowingCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFollowerCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserFollowCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserFollowCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserFollowCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserFollowCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFollowingCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getFollowerCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserFollowCount.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserFollowCount} returns this
 */
proto.UserFollowCount.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 following_count = 2;
 * @return {number}
 */
proto.UserFollowCount.prototype.getFollowingCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserFollowCount} returns this
 */
proto.UserFollowCount.prototype.setFollowingCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 follower_count = 3;
 * @return {number}
 */
proto.UserFollowCount.prototype.getFollowerCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserFollowCount} returns this
 */
proto.UserFollowCount.prototype.setFollowerCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserBlock.prototype.toObject = function(opt_includeInstance) {
  return proto.UserBlock.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserBlock} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserBlock.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isBlocking: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isBlocked: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserBlock}
 */
proto.UserBlock.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserBlock;
  return proto.UserBlock.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserBlock} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserBlock}
 */
proto.UserBlock.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlocking(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBlocked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserBlock.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserBlock.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserBlock} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserBlock.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsBlocking();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsBlocked();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserBlock.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserBlock} returns this
 */
proto.UserBlock.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_blocking = 2;
 * @return {boolean}
 */
proto.UserBlock.prototype.getIsBlocking = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserBlock} returns this
 */
proto.UserBlock.prototype.setIsBlocking = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool is_blocked = 3;
 * @return {boolean}
 */
proto.UserBlock.prototype.getIsBlocked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserBlock} returns this
 */
proto.UserBlock.prototype.setIsBlocked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserWithdraw.prototype.toObject = function(opt_includeInstance) {
  return proto.UserWithdraw.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserWithdraw} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserWithdraw.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isWithdraw: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserWithdraw}
 */
proto.UserWithdraw.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserWithdraw;
  return proto.UserWithdraw.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserWithdraw} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserWithdraw}
 */
proto.UserWithdraw.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWithdraw(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserWithdraw.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserWithdraw.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserWithdraw} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserWithdraw.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsWithdraw();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserWithdraw.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserWithdraw} returns this
 */
proto.UserWithdraw.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool is_withdraw = 2;
 * @return {boolean}
 */
proto.UserWithdraw.prototype.getIsWithdraw = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserWithdraw} returns this
 */
proto.UserWithdraw.prototype.setIsWithdraw = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Prefecture.prototype.toObject = function(opt_includeInstance) {
  return proto.Prefecture.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Prefecture} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Prefecture.toObject = function(includeInstance, msg) {
  var f, obj = {
    prefectureId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Prefecture}
 */
proto.Prefecture.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Prefecture;
  return proto.Prefecture.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Prefecture} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Prefecture}
 */
proto.Prefecture.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrefectureId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Prefecture.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Prefecture.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Prefecture} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Prefecture.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrefectureId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 prefecture_id = 1;
 * @return {number}
 */
proto.Prefecture.prototype.getPrefectureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Prefecture} returns this
 */
proto.Prefecture.prototype.setPrefectureId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Prefecture.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Prefecture} returns this
 */
proto.Prefecture.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Town.prototype.toObject = function(opt_includeInstance) {
  return proto.Town.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Town} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Town.toObject = function(includeInstance, msg) {
  var f, obj = {
    townId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    prefectureId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Town}
 */
proto.Town.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Town;
  return proto.Town.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Town} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Town}
 */
proto.Town.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTownId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPrefectureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Town.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Town.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Town} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Town.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTownId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrefectureId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional int64 town_id = 1;
 * @return {number}
 */
proto.Town.prototype.getTownId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Town} returns this
 */
proto.Town.prototype.setTownId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.Town.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Town} returns this
 */
proto.Town.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 prefecture_id = 3;
 * @return {number}
 */
proto.Town.prototype.getPrefectureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Town} returns this
 */
proto.Town.prototype.setPrefectureId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TwitterAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.TwitterAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TwitterAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TwitterAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    twitterUserId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    screenName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profileImageUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TwitterAccount}
 */
proto.TwitterAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TwitterAccount;
  return proto.TwitterAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TwitterAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TwitterAccount}
 */
proto.TwitterAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTwitterUserId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setScreenName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfileImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TwitterAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TwitterAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TwitterAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TwitterAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTwitterUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getScreenName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfileImageUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string twitter_user_id = 1;
 * @return {string}
 */
proto.TwitterAccount.prototype.getTwitterUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TwitterAccount} returns this
 */
proto.TwitterAccount.prototype.setTwitterUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.TwitterAccount.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TwitterAccount} returns this
 */
proto.TwitterAccount.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string screen_name = 3;
 * @return {string}
 */
proto.TwitterAccount.prototype.getScreenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TwitterAccount} returns this
 */
proto.TwitterAccount.prototype.setScreenName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_image_url = 4;
 * @return {string}
 */
proto.TwitterAccount.prototype.getProfileImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TwitterAccount} returns this
 */
proto.TwitterAccount.prototype.setProfileImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Following.prototype.toObject = function(opt_includeInstance) {
  return proto.Following.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Following} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Following.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUserItem: (f = msg.getTargetUserItem()) && proto.UserItem.toObject(includeInstance, f),
    lastFollowedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    targetUserFollow: (f = msg.getTargetUserFollow()) && proto.UserFollow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Following}
 */
proto.Following.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Following;
  return proto.Following.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Following} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Following}
 */
proto.Following.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setTargetUserItem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFollowedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = new proto.UserFollow;
      reader.readMessage(value,proto.UserFollow.deserializeBinaryFromReader);
      msg.setTargetUserFollow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Following.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Following.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Following} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Following.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUserItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getLastFollowedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTargetUserFollow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.UserFollow.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.Following.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Following} returns this
 */
proto.Following.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserItem target_user_item = 2;
 * @return {?proto.UserItem}
 */
proto.Following.prototype.getTargetUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 2));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.Following} returns this
*/
proto.Following.prototype.setTargetUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Following} returns this
 */
proto.Following.prototype.clearTargetUserItem = function() {
  return this.setTargetUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Following.prototype.hasTargetUserItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 last_followed_at = 3;
 * @return {number}
 */
proto.Following.prototype.getLastFollowedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Following} returns this
 */
proto.Following.prototype.setLastFollowedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.Following.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Following} returns this
 */
proto.Following.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.Following.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Following} returns this
 */
proto.Following.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional UserFollow target_user_follow = 6;
 * @return {?proto.UserFollow}
 */
proto.Following.prototype.getTargetUserFollow = function() {
  return /** @type{?proto.UserFollow} */ (
    jspb.Message.getWrapperField(this, proto.UserFollow, 6));
};


/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.Following} returns this
*/
proto.Following.prototype.setTargetUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Following} returns this
 */
proto.Following.prototype.clearTargetUserFollow = function() {
  return this.setTargetUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Following.prototype.hasTargetUserFollow = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Follower.prototype.toObject = function(opt_includeInstance) {
  return proto.Follower.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Follower} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Follower.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUserItem: (f = msg.getTargetUserItem()) && proto.UserItem.toObject(includeInstance, f),
    lastFollowedAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    targetUserFollow: (f = msg.getTargetUserFollow()) && proto.UserFollow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Follower}
 */
proto.Follower.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Follower;
  return proto.Follower.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Follower} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Follower}
 */
proto.Follower.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setTargetUserItem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastFollowedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 6:
      var value = new proto.UserFollow;
      reader.readMessage(value,proto.UserFollow.deserializeBinaryFromReader);
      msg.setTargetUserFollow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Follower.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Follower.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Follower} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Follower.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUserItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getLastFollowedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getTargetUserFollow();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.UserFollow.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.Follower.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Follower} returns this
 */
proto.Follower.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserItem target_user_item = 2;
 * @return {?proto.UserItem}
 */
proto.Follower.prototype.getTargetUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 2));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.Follower} returns this
*/
proto.Follower.prototype.setTargetUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Follower} returns this
 */
proto.Follower.prototype.clearTargetUserItem = function() {
  return this.setTargetUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Follower.prototype.hasTargetUserItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 last_followed_at = 3;
 * @return {number}
 */
proto.Follower.prototype.getLastFollowedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Follower} returns this
 */
proto.Follower.prototype.setLastFollowedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 created_at = 4;
 * @return {number}
 */
proto.Follower.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Follower} returns this
 */
proto.Follower.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 updated_at = 5;
 * @return {number}
 */
proto.Follower.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.Follower} returns this
 */
proto.Follower.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional UserFollow target_user_follow = 6;
 * @return {?proto.UserFollow}
 */
proto.Follower.prototype.getTargetUserFollow = function() {
  return /** @type{?proto.UserFollow} */ (
    jspb.Message.getWrapperField(this, proto.UserFollow, 6));
};


/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.Follower} returns this
*/
proto.Follower.prototype.setTargetUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Follower} returns this
 */
proto.Follower.prototype.clearTargetUserFollow = function() {
  return this.setTargetUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Follower.prototype.hasTargetUserFollow = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Blocking.prototype.toObject = function(opt_includeInstance) {
  return proto.Blocking.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Blocking} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Blocking.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    targetUserItem: (f = msg.getTargetUserItem()) && proto.UserItem.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    userBlock: (f = msg.getUserBlock()) && proto.UserBlock.toObject(includeInstance, f),
    executorUser: (f = msg.getExecutorUser()) && proto.User.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Blocking}
 */
proto.Blocking.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Blocking;
  return proto.Blocking.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Blocking} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Blocking}
 */
proto.Blocking.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setTargetUserItem(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedAt(value);
      break;
    case 5:
      var value = new proto.UserBlock;
      reader.readMessage(value,proto.UserBlock.deserializeBinaryFromReader);
      msg.setUserBlock(value);
      break;
    case 6:
      var value = new proto.User;
      reader.readMessage(value,proto.User.deserializeBinaryFromReader);
      msg.setExecutorUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Blocking.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Blocking.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Blocking} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Blocking.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTargetUserItem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUserBlock();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.UserBlock.serializeBinaryToWriter
    );
  }
  f = message.getExecutorUser();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.Blocking.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Blocking} returns this
 */
proto.Blocking.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional UserItem target_user_item = 2;
 * @return {?proto.UserItem}
 */
proto.Blocking.prototype.getTargetUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 2));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.Blocking} returns this
*/
proto.Blocking.prototype.setTargetUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Blocking} returns this
 */
proto.Blocking.prototype.clearTargetUserItem = function() {
  return this.setTargetUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Blocking.prototype.hasTargetUserItem = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.Blocking.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Blocking} returns this
 */
proto.Blocking.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 updated_at = 4;
 * @return {number}
 */
proto.Blocking.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Blocking} returns this
 */
proto.Blocking.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional UserBlock user_block = 5;
 * @return {?proto.UserBlock}
 */
proto.Blocking.prototype.getUserBlock = function() {
  return /** @type{?proto.UserBlock} */ (
    jspb.Message.getWrapperField(this, proto.UserBlock, 5));
};


/**
 * @param {?proto.UserBlock|undefined} value
 * @return {!proto.Blocking} returns this
*/
proto.Blocking.prototype.setUserBlock = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Blocking} returns this
 */
proto.Blocking.prototype.clearUserBlock = function() {
  return this.setUserBlock(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Blocking.prototype.hasUserBlock = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional User executor_user = 6;
 * @return {?proto.User}
 */
proto.Blocking.prototype.getExecutorUser = function() {
  return /** @type{?proto.User} */ (
    jspb.Message.getWrapperField(this, proto.User, 6));
};


/**
 * @param {?proto.User|undefined} value
 * @return {!proto.Blocking} returns this
*/
proto.Blocking.prototype.setExecutorUser = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Blocking} returns this
 */
proto.Blocking.prototype.clearExecutorUser = function() {
  return this.setExecutorUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Blocking.prototype.hasExecutorUser = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UserBanHistory.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserBanHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.UserBanHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserBanHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserBanHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    banHistoryList: jspb.Message.toObjectList(msg.getBanHistoryList(),
    proto.Ban.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserBanHistory}
 */
proto.UserBanHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserBanHistory;
  return proto.UserBanHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserBanHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserBanHistory}
 */
proto.UserBanHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 2:
      var value = new proto.Ban;
      reader.readMessage(value,proto.Ban.deserializeBinaryFromReader);
      msg.addBanHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserBanHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserBanHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserBanHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserBanHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBanHistoryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.Ban.serializeBinaryToWriter
    );
  }
};


/**
 * optional string user_id = 1;
 * @return {string}
 */
proto.UserBanHistory.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserBanHistory} returns this
 */
proto.UserBanHistory.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Ban ban_history = 2;
 * @return {!Array<!proto.Ban>}
 */
proto.UserBanHistory.prototype.getBanHistoryList = function() {
  return /** @type{!Array<!proto.Ban>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Ban, 2));
};


/**
 * @param {!Array<!proto.Ban>} value
 * @return {!proto.UserBanHistory} returns this
*/
proto.UserBanHistory.prototype.setBanHistoryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.Ban=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Ban}
 */
proto.UserBanHistory.prototype.addBanHistory = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.Ban, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UserBanHistory} returns this
 */
proto.UserBanHistory.prototype.clearBanHistoryList = function() {
  return this.setBanHistoryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Ban.prototype.toObject = function(opt_includeInstance) {
  return proto.Ban.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Ban} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ban.toObject = function(includeInstance, msg) {
  var f, obj = {
    violationCategory: (f = msg.getViolationCategory()) && master_violation_entity_pb.ViolationCategory.toObject(includeInstance, f),
    violationTarget: (f = msg.getViolationTarget()) && master_violation_entity_pb.ViolationTarget.toObject(includeInstance, f),
    banLevel: (f = msg.getBanLevel()) && master_violation_entity_pb.BanLevel.toObject(includeInstance, f),
    releaseAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Ban}
 */
proto.Ban.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Ban;
  return proto.Ban.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Ban} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Ban}
 */
proto.Ban.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new master_violation_entity_pb.ViolationCategory;
      reader.readMessage(value,master_violation_entity_pb.ViolationCategory.deserializeBinaryFromReader);
      msg.setViolationCategory(value);
      break;
    case 2:
      var value = new master_violation_entity_pb.ViolationTarget;
      reader.readMessage(value,master_violation_entity_pb.ViolationTarget.deserializeBinaryFromReader);
      msg.setViolationTarget(value);
      break;
    case 3:
      var value = new master_violation_entity_pb.BanLevel;
      reader.readMessage(value,master_violation_entity_pb.BanLevel.deserializeBinaryFromReader);
      msg.setBanLevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReleaseAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Ban.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Ban.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Ban} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Ban.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViolationCategory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      master_violation_entity_pb.ViolationCategory.serializeBinaryToWriter
    );
  }
  f = message.getViolationTarget();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      master_violation_entity_pb.ViolationTarget.serializeBinaryToWriter
    );
  }
  f = message.getBanLevel();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      master_violation_entity_pb.BanLevel.serializeBinaryToWriter
    );
  }
  f = message.getReleaseAt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional jp.singcolor.master.ViolationCategory violation_category = 1;
 * @return {?proto.jp.singcolor.master.ViolationCategory}
 */
proto.Ban.prototype.getViolationCategory = function() {
  return /** @type{?proto.jp.singcolor.master.ViolationCategory} */ (
    jspb.Message.getWrapperField(this, master_violation_entity_pb.ViolationCategory, 1));
};


/**
 * @param {?proto.jp.singcolor.master.ViolationCategory|undefined} value
 * @return {!proto.Ban} returns this
*/
proto.Ban.prototype.setViolationCategory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ban} returns this
 */
proto.Ban.prototype.clearViolationCategory = function() {
  return this.setViolationCategory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ban.prototype.hasViolationCategory = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional jp.singcolor.master.ViolationTarget violation_target = 2;
 * @return {?proto.jp.singcolor.master.ViolationTarget}
 */
proto.Ban.prototype.getViolationTarget = function() {
  return /** @type{?proto.jp.singcolor.master.ViolationTarget} */ (
    jspb.Message.getWrapperField(this, master_violation_entity_pb.ViolationTarget, 2));
};


/**
 * @param {?proto.jp.singcolor.master.ViolationTarget|undefined} value
 * @return {!proto.Ban} returns this
*/
proto.Ban.prototype.setViolationTarget = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ban} returns this
 */
proto.Ban.prototype.clearViolationTarget = function() {
  return this.setViolationTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ban.prototype.hasViolationTarget = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional jp.singcolor.master.BanLevel ban_level = 3;
 * @return {?proto.jp.singcolor.master.BanLevel}
 */
proto.Ban.prototype.getBanLevel = function() {
  return /** @type{?proto.jp.singcolor.master.BanLevel} */ (
    jspb.Message.getWrapperField(this, master_violation_entity_pb.BanLevel, 3));
};


/**
 * @param {?proto.jp.singcolor.master.BanLevel|undefined} value
 * @return {!proto.Ban} returns this
*/
proto.Ban.prototype.setBanLevel = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Ban} returns this
 */
proto.Ban.prototype.clearBanLevel = function() {
  return this.setBanLevel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Ban.prototype.hasBanLevel = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 release_at = 4;
 * @return {number}
 */
proto.Ban.prototype.getReleaseAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.Ban} returns this
 */
proto.Ban.prototype.setReleaseAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.UserProfileScreening.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserProfileScreening.prototype.toObject = function(opt_includeInstance) {
  return proto.UserProfileScreening.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserProfileScreening} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserProfileScreening.toObject = function(includeInstance, msg) {
  var f, obj = {
    userProfile: (f = msg.getUserProfile()) && proto.UserProfile.toObject(includeInstance, f),
    screeningTargetsList: jspb.Message.toObjectList(msg.getScreeningTargetsList(),
    master_violation_entity_pb.ViolationTarget.toObject, includeInstance),
    liveBackgroundImageUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserProfileScreening}
 */
proto.UserProfileScreening.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserProfileScreening;
  return proto.UserProfileScreening.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserProfileScreening} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserProfileScreening}
 */
proto.UserProfileScreening.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserProfile;
      reader.readMessage(value,proto.UserProfile.deserializeBinaryFromReader);
      msg.setUserProfile(value);
      break;
    case 2:
      var value = new master_violation_entity_pb.ViolationTarget;
      reader.readMessage(value,master_violation_entity_pb.ViolationTarget.deserializeBinaryFromReader);
      msg.addScreeningTargets(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveBackgroundImageUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserProfileScreening.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserProfileScreening.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserProfileScreening} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserProfileScreening.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserProfile.serializeBinaryToWriter
    );
  }
  f = message.getScreeningTargetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      master_violation_entity_pb.ViolationTarget.serializeBinaryToWriter
    );
  }
  f = message.getLiveBackgroundImageUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional UserProfile user_profile = 1;
 * @return {?proto.UserProfile}
 */
proto.UserProfileScreening.prototype.getUserProfile = function() {
  return /** @type{?proto.UserProfile} */ (
    jspb.Message.getWrapperField(this, proto.UserProfile, 1));
};


/**
 * @param {?proto.UserProfile|undefined} value
 * @return {!proto.UserProfileScreening} returns this
*/
proto.UserProfileScreening.prototype.setUserProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserProfileScreening} returns this
 */
proto.UserProfileScreening.prototype.clearUserProfile = function() {
  return this.setUserProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserProfileScreening.prototype.hasUserProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated jp.singcolor.master.ViolationTarget screening_targets = 2;
 * @return {!Array<!proto.jp.singcolor.master.ViolationTarget>}
 */
proto.UserProfileScreening.prototype.getScreeningTargetsList = function() {
  return /** @type{!Array<!proto.jp.singcolor.master.ViolationTarget>} */ (
    jspb.Message.getRepeatedWrapperField(this, master_violation_entity_pb.ViolationTarget, 2));
};


/**
 * @param {!Array<!proto.jp.singcolor.master.ViolationTarget>} value
 * @return {!proto.UserProfileScreening} returns this
*/
proto.UserProfileScreening.prototype.setScreeningTargetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.jp.singcolor.master.ViolationTarget=} opt_value
 * @param {number=} opt_index
 * @return {!proto.jp.singcolor.master.ViolationTarget}
 */
proto.UserProfileScreening.prototype.addScreeningTargets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.jp.singcolor.master.ViolationTarget, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.UserProfileScreening} returns this
 */
proto.UserProfileScreening.prototype.clearScreeningTargetsList = function() {
  return this.setScreeningTargetsList([]);
};


/**
 * optional string live_background_image_url = 3;
 * @return {string}
 */
proto.UserProfileScreening.prototype.getLiveBackgroundImageUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserProfileScreening} returns this
 */
proto.UserProfileScreening.prototype.setLiveBackgroundImageUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserItemWithFollow.prototype.toObject = function(opt_includeInstance) {
  return proto.UserItemWithFollow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserItemWithFollow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserItemWithFollow.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && proto.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && proto.UserFollow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserItemWithFollow}
 */
proto.UserItemWithFollow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserItemWithFollow;
  return proto.UserItemWithFollow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserItemWithFollow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserItemWithFollow}
 */
proto.UserItemWithFollow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new proto.UserFollow;
      reader.readMessage(value,proto.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserItemWithFollow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserItemWithFollow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserItemWithFollow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserItemWithFollow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserFollow.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.UserItemWithFollow.prototype.getUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 1));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.UserItemWithFollow} returns this
*/
proto.UserItemWithFollow.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserItemWithFollow} returns this
 */
proto.UserItemWithFollow.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserItemWithFollow.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.UserItemWithFollow.prototype.getUserFollow = function() {
  return /** @type{?proto.UserFollow} */ (
    jspb.Message.getWrapperField(this, proto.UserFollow, 2));
};


/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.UserItemWithFollow} returns this
*/
proto.UserItemWithFollow.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserItemWithFollow} returns this
 */
proto.UserItemWithFollow.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserItemWithFollow.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SearchUser.prototype.toObject = function(opt_includeInstance) {
  return proto.SearchUser.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SearchUser} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchUser.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && proto.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && proto.UserFollow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SearchUser}
 */
proto.SearchUser.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SearchUser;
  return proto.SearchUser.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SearchUser} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SearchUser}
 */
proto.SearchUser.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new proto.UserFollow;
      reader.readMessage(value,proto.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SearchUser.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SearchUser.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SearchUser} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SearchUser.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserFollow.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.SearchUser.prototype.getUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 1));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.SearchUser} returns this
*/
proto.SearchUser.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SearchUser} returns this
 */
proto.SearchUser.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SearchUser.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.SearchUser.prototype.getUserFollow = function() {
  return /** @type{?proto.UserFollow} */ (
    jspb.Message.getWrapperField(this, proto.UserFollow, 2));
};


/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.SearchUser} returns this
*/
proto.SearchUser.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SearchUser} returns this
 */
proto.SearchUser.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SearchUser.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserItemWithFavePoint.prototype.toObject = function(opt_includeInstance) {
  return proto.UserItemWithFavePoint.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserItemWithFavePoint} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserItemWithFavePoint.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && proto.UserItem.toObject(includeInstance, f),
    termlyFavePoints: jspb.Message.getFieldWithDefault(msg, 2, 0),
    monthlyFavePoints: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserItemWithFavePoint}
 */
proto.UserItemWithFavePoint.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserItemWithFavePoint;
  return proto.UserItemWithFavePoint.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserItemWithFavePoint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserItemWithFavePoint}
 */
proto.UserItemWithFavePoint.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermlyFavePoints(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonthlyFavePoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserItemWithFavePoint.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserItemWithFavePoint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserItemWithFavePoint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserItemWithFavePoint.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getTermlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMonthlyFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.UserItemWithFavePoint.prototype.getUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 1));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.UserItemWithFavePoint} returns this
*/
proto.UserItemWithFavePoint.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserItemWithFavePoint} returns this
 */
proto.UserItemWithFavePoint.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserItemWithFavePoint.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 termly_fave_points = 2;
 * @return {number}
 */
proto.UserItemWithFavePoint.prototype.getTermlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserItemWithFavePoint} returns this
 */
proto.UserItemWithFavePoint.prototype.setTermlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 monthly_fave_points = 3;
 * @return {number}
 */
proto.UserItemWithFavePoint.prototype.getMonthlyFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserItemWithFavePoint} returns this
 */
proto.UserItemWithFavePoint.prototype.setMonthlyFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RankingLiver.prototype.toObject = function(opt_includeInstance) {
  return proto.RankingLiver.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RankingLiver} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankingLiver.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && proto.UserItem.toObject(includeInstance, f),
    userFollow: (f = msg.getUserFollow()) && proto.UserFollow.toObject(includeInstance, f),
    rank: jspb.Message.getFieldWithDefault(msg, 3, 0),
    liveScore: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hidden: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RankingLiver}
 */
proto.RankingLiver.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RankingLiver;
  return proto.RankingLiver.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RankingLiver} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RankingLiver}
 */
proto.RankingLiver.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = new proto.UserFollow;
      reader.readMessage(value,proto.UserFollow.deserializeBinaryFromReader);
      msg.setUserFollow(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRank(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLiveScore(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHidden(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RankingLiver.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RankingLiver.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RankingLiver} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankingLiver.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getUserFollow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserFollow.serializeBinaryToWriter
    );
  }
  f = message.getRank();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getLiveScore();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHidden();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.RankingLiver.prototype.getUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 1));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.RankingLiver} returns this
*/
proto.RankingLiver.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RankingLiver} returns this
 */
proto.RankingLiver.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RankingLiver.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserFollow user_follow = 2;
 * @return {?proto.UserFollow}
 */
proto.RankingLiver.prototype.getUserFollow = function() {
  return /** @type{?proto.UserFollow} */ (
    jspb.Message.getWrapperField(this, proto.UserFollow, 2));
};


/**
 * @param {?proto.UserFollow|undefined} value
 * @return {!proto.RankingLiver} returns this
*/
proto.RankingLiver.prototype.setUserFollow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RankingLiver} returns this
 */
proto.RankingLiver.prototype.clearUserFollow = function() {
  return this.setUserFollow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RankingLiver.prototype.hasUserFollow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 rank = 3;
 * @return {number}
 */
proto.RankingLiver.prototype.getRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.RankingLiver} returns this
 */
proto.RankingLiver.prototype.setRank = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 live_score = 4;
 * @return {number}
 */
proto.RankingLiver.prototype.getLiveScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.RankingLiver} returns this
 */
proto.RankingLiver.prototype.setLiveScore = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool hidden = 5;
 * @return {boolean}
 */
proto.RankingLiver.prototype.getHidden = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RankingLiver} returns this
 */
proto.RankingLiver.prototype.setHidden = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RankingTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.RankingTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RankingTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankingTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    rankingTermId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    enableTimelineFilter: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    enableGenderFilter: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    enableWorldFilter: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RankingTerm}
 */
proto.RankingTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RankingTerm;
  return proto.RankingTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RankingTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RankingTerm}
 */
proto.RankingTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRankingTermId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableTimelineFilter(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableGenderFilter(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnableWorldFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RankingTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RankingTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RankingTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RankingTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRankingTermId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEnableTimelineFilter();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getEnableGenderFilter();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEnableWorldFilter();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string ranking_term_id = 1;
 * @return {string}
 */
proto.RankingTerm.prototype.getRankingTermId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RankingTerm} returns this
 */
proto.RankingTerm.prototype.setRankingTermId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.RankingTerm.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RankingTerm} returns this
 */
proto.RankingTerm.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool enable_timeline_filter = 3;
 * @return {boolean}
 */
proto.RankingTerm.prototype.getEnableTimelineFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RankingTerm} returns this
 */
proto.RankingTerm.prototype.setEnableTimelineFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool enable_gender_filter = 4;
 * @return {boolean}
 */
proto.RankingTerm.prototype.getEnableGenderFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RankingTerm} returns this
 */
proto.RankingTerm.prototype.setEnableGenderFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool enable_world_filter = 5;
 * @return {boolean}
 */
proto.RankingTerm.prototype.getEnableWorldFilter = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RankingTerm} returns this
 */
proto.RankingTerm.prototype.setEnableWorldFilter = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UnreadNotificationInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.UnreadNotificationInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UnreadNotificationInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnreadNotificationInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemTabLastReadAt: jspb.Message.getFieldWithDefault(msg, 1, 0),
    systemTabUnreadCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    operatorTabLastReadAt: jspb.Message.getFieldWithDefault(msg, 3, 0),
    operatorTabUnreadCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UnreadNotificationInfo}
 */
proto.UnreadNotificationInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UnreadNotificationInfo;
  return proto.UnreadNotificationInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UnreadNotificationInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UnreadNotificationInfo}
 */
proto.UnreadNotificationInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSystemTabLastReadAt(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSystemTabUnreadCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatorTabLastReadAt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOperatorTabUnreadCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UnreadNotificationInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UnreadNotificationInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UnreadNotificationInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UnreadNotificationInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemTabLastReadAt();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getSystemTabUnreadCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getOperatorTabLastReadAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOperatorTabUnreadCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 system_tab_last_read_at = 1;
 * @return {number}
 */
proto.UnreadNotificationInfo.prototype.getSystemTabLastReadAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.UnreadNotificationInfo} returns this
 */
proto.UnreadNotificationInfo.prototype.setSystemTabLastReadAt = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 system_tab_unread_count = 2;
 * @return {number}
 */
proto.UnreadNotificationInfo.prototype.getSystemTabUnreadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UnreadNotificationInfo} returns this
 */
proto.UnreadNotificationInfo.prototype.setSystemTabUnreadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 operator_tab_last_read_at = 3;
 * @return {number}
 */
proto.UnreadNotificationInfo.prototype.getOperatorTabLastReadAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.UnreadNotificationInfo} returns this
 */
proto.UnreadNotificationInfo.prototype.setOperatorTabLastReadAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 operator_tab_unread_count = 4;
 * @return {number}
 */
proto.UnreadNotificationInfo.prototype.getOperatorTabUnreadCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.UnreadNotificationInfo} returns this
 */
proto.UnreadNotificationInfo.prototype.setOperatorTabUnreadCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AcquireDiamondCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.AcquireDiamondCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AcquireDiamondCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AcquireDiamondCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSatisfied: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AcquireDiamondCondition}
 */
proto.AcquireDiamondCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AcquireDiamondCondition;
  return proto.AcquireDiamondCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AcquireDiamondCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AcquireDiamondCondition}
 */
proto.AcquireDiamondCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSatisfied(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AcquireDiamondCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AcquireDiamondCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AcquireDiamondCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AcquireDiamondCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSatisfied();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional bool is_satisfied = 1;
 * @return {boolean}
 */
proto.AcquireDiamondCondition.prototype.getIsSatisfied = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.AcquireDiamondCondition} returns this
 */
proto.AcquireDiamondCondition.prototype.setIsSatisfied = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AcquireDiamondCondition.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AcquireDiamondCondition} returns this
 */
proto.AcquireDiamondCondition.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.MonthlyStatisticsPageKey.prototype.toObject = function(opt_includeInstance) {
  return proto.MonthlyStatisticsPageKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.MonthlyStatisticsPageKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MonthlyStatisticsPageKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.MonthlyStatisticsPageKey}
 */
proto.MonthlyStatisticsPageKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.MonthlyStatisticsPageKey;
  return proto.MonthlyStatisticsPageKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.MonthlyStatisticsPageKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.MonthlyStatisticsPageKey}
 */
proto.MonthlyStatisticsPageKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.MonthlyStatisticsPageKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.MonthlyStatisticsPageKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.MonthlyStatisticsPageKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.MonthlyStatisticsPageKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 year = 1;
 * @return {number}
 */
proto.MonthlyStatisticsPageKey.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.MonthlyStatisticsPageKey} returns this
 */
proto.MonthlyStatisticsPageKey.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 month = 2;
 * @return {number}
 */
proto.MonthlyStatisticsPageKey.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.MonthlyStatisticsPageKey} returns this
 */
proto.MonthlyStatisticsPageKey.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AcquiredDiamondDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.AcquiredDiamondDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AcquiredDiamondDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AcquiredDiamondDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalAmount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    chatDiamondAmount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    usedChatDiamondAcquisitionRight: jspb.Message.getFieldWithDefault(msg, 3, ""),
    chatDiamondUnitPrice: jspb.Message.getFieldWithDefault(msg, 4, 0),
    singDiamondAmount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    singDiamondUnitPrice: jspb.Message.getFieldWithDefault(msg, 6, 0),
    singDiamondStartDashBonus: jspb.Message.getFieldWithDefault(msg, 12, 0),
    singDiamondRate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    usedSingDiamondAcquisitionRight: jspb.Message.getFieldWithDefault(msg, 8, ""),
    distributedAccumulationDiamondFundAmount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    supportCommissionAmount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    membershipDiamondAmount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    leagueId: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AcquiredDiamondDetail}
 */
proto.AcquiredDiamondDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AcquiredDiamondDetail;
  return proto.AcquiredDiamondDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AcquiredDiamondDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AcquiredDiamondDetail}
 */
proto.AcquiredDiamondDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalAmount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChatDiamondAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsedChatDiamondAcquisitionRight(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setChatDiamondUnitPrice(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSingDiamondAmount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSingDiamondUnitPrice(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSingDiamondStartDashBonus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSingDiamondRate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsedSingDiamondAcquisitionRight(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDistributedAccumulationDiamondFundAmount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSupportCommissionAmount(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMembershipDiamondAmount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLeagueId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AcquiredDiamondDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AcquiredDiamondDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AcquiredDiamondDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AcquiredDiamondDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getChatDiamondAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getUsedChatDiamondAcquisitionRight();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getChatDiamondUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getSingDiamondAmount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getSingDiamondUnitPrice();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSingDiamondStartDashBonus();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getSingDiamondRate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUsedSingDiamondAcquisitionRight();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDistributedAccumulationDiamondFundAmount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSupportCommissionAmount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getMembershipDiamondAmount();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getLeagueId();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int64 total_amount = 1;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 chat_diamond_amount = 2;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getChatDiamondAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setChatDiamondAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string used_chat_diamond_acquisition_right = 3;
 * @return {string}
 */
proto.AcquiredDiamondDetail.prototype.getUsedChatDiamondAcquisitionRight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setUsedChatDiamondAcquisitionRight = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 chat_diamond_unit_price = 4;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getChatDiamondUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setChatDiamondUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 sing_diamond_amount = 5;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getSingDiamondAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setSingDiamondAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 sing_diamond_unit_price = 6;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getSingDiamondUnitPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setSingDiamondUnitPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 sing_diamond_start_dash_bonus = 12;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getSingDiamondStartDashBonus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setSingDiamondStartDashBonus = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string sing_diamond_rate = 7;
 * @return {string}
 */
proto.AcquiredDiamondDetail.prototype.getSingDiamondRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setSingDiamondRate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string used_sing_diamond_acquisition_right = 8;
 * @return {string}
 */
proto.AcquiredDiamondDetail.prototype.getUsedSingDiamondAcquisitionRight = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setUsedSingDiamondAcquisitionRight = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int64 distributed_accumulation_diamond_fund_amount = 9;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getDistributedAccumulationDiamondFundAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setDistributedAccumulationDiamondFundAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 support_commission_amount = 10;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getSupportCommissionAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setSupportCommissionAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 membership_diamond_amount = 13;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getMembershipDiamondAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setMembershipDiamondAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int64 league_id = 11;
 * @return {number}
 */
proto.AcquiredDiamondDetail.prototype.getLeagueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.AcquiredDiamondDetail} returns this
 */
proto.AcquiredDiamondDetail.prototype.setLeagueId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StatisticsRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.StatisticsRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StatisticsRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatisticsRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    termStartTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    termLabel: jspb.Message.getFieldWithDefault(msg, 13, ""),
    closingTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    acquiredPoint: jspb.Message.getFieldWithDefault(msg, 4, 0),
    fromLeagueId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    toLeagueId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    exceedingExp: jspb.Message.getFieldWithDefault(msg, 15, 0),
    diamondAmountDetail: (f = msg.getDiamondAmountDetail()) && proto.AcquiredDiamondDetail.toObject(includeInstance, f),
    accumulatedDiamond: jspb.Message.getFieldWithDefault(msg, 12, 0),
    liveDuration: jspb.Message.getFieldWithDefault(msg, 8, 0),
    songCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    songFaveCount: jspb.Message.getFieldWithDefault(msg, 10, 0),
    songFaveTotalCount: jspb.Message.getFieldWithDefault(msg, 14, 0),
    favePoints: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StatisticsRecord}
 */
proto.StatisticsRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StatisticsRecord;
  return proto.StatisticsRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StatisticsRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StatisticsRecord}
 */
proto.StatisticsRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTermStartTime(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTermLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClosingTime(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAcquiredPoint(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFromLeagueId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setToLeagueId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExceedingExp(value);
      break;
    case 7:
      var value = new proto.AcquiredDiamondDetail;
      reader.readMessage(value,proto.AcquiredDiamondDetail.deserializeBinaryFromReader);
      msg.setDiamondAmountDetail(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAccumulatedDiamond(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLiveDuration(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSongCount(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSongFaveCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSongFaveTotalCount(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setFavePoints(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StatisticsRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StatisticsRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StatisticsRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StatisticsRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTermStartTime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTermLabel();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getClosingTime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAcquiredPoint();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getFromLeagueId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getToLeagueId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getExceedingExp();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getDiamondAmountDetail();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.AcquiredDiamondDetail.serializeBinaryToWriter
    );
  }
  f = message.getAccumulatedDiamond();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getLiveDuration();
  if (f !== 0) {
    writer.writeInt64(
      8,
      f
    );
  }
  f = message.getSongCount();
  if (f !== 0) {
    writer.writeInt64(
      9,
      f
    );
  }
  f = message.getSongFaveCount();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getSongFaveTotalCount();
  if (f !== 0) {
    writer.writeInt64(
      14,
      f
    );
  }
  f = message.getFavePoints();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
};


/**
 * optional int64 term_start_time = 1;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getTermStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setTermStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string term_label = 13;
 * @return {string}
 */
proto.StatisticsRecord.prototype.getTermLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setTermLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int64 closing_time = 2;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getClosingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setClosingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool closed = 3;
 * @return {boolean}
 */
proto.StatisticsRecord.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int64 acquired_point = 4;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getAcquiredPoint = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setAcquiredPoint = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 from_league_id = 5;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getFromLeagueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setFromLeagueId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 to_league_id = 6;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getToLeagueId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setToLeagueId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 exceeding_exp = 15;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getExceedingExp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setExceedingExp = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional AcquiredDiamondDetail diamond_amount_detail = 7;
 * @return {?proto.AcquiredDiamondDetail}
 */
proto.StatisticsRecord.prototype.getDiamondAmountDetail = function() {
  return /** @type{?proto.AcquiredDiamondDetail} */ (
    jspb.Message.getWrapperField(this, proto.AcquiredDiamondDetail, 7));
};


/**
 * @param {?proto.AcquiredDiamondDetail|undefined} value
 * @return {!proto.StatisticsRecord} returns this
*/
proto.StatisticsRecord.prototype.setDiamondAmountDetail = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.clearDiamondAmountDetail = function() {
  return this.setDiamondAmountDetail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StatisticsRecord.prototype.hasDiamondAmountDetail = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int64 accumulated_diamond = 12;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getAccumulatedDiamond = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setAccumulatedDiamond = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int64 live_duration = 8;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getLiveDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setLiveDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int64 song_count = 9;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getSongCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setSongCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int64 song_fave_count = 10;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getSongFaveCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setSongFaveCount = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 song_fave_total_count = 14;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getSongFaveTotalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setSongFaveTotalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 fave_points = 11;
 * @return {number}
 */
proto.StatisticsRecord.prototype.getFavePoints = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.StatisticsRecord} returns this
 */
proto.StatisticsRecord.prototype.setFavePoints = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DayOfLiverTerm.prototype.toObject = function(opt_includeInstance) {
  return proto.DayOfLiverTerm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DayOfLiverTerm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DayOfLiverTerm.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    label: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DayOfLiverTerm}
 */
proto.DayOfLiverTerm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DayOfLiverTerm;
  return proto.DayOfLiverTerm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DayOfLiverTerm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DayOfLiverTerm}
 */
proto.DayOfLiverTerm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DayOfLiverTerm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DayOfLiverTerm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DayOfLiverTerm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DayOfLiverTerm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.DayOfLiverTerm.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DayOfLiverTerm} returns this
 */
proto.DayOfLiverTerm.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.DayOfLiverTerm.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DayOfLiverTerm} returns this
 */
proto.DayOfLiverTerm.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.AvailableClosingTime.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AvailableClosingTime.prototype.toObject = function(opt_includeInstance) {
  return proto.AvailableClosingTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AvailableClosingTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableClosingTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    listClosingTimeJstList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dayOfLiverTerm: (f = msg.getDayOfLiverTerm()) && proto.DayOfLiverTerm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AvailableClosingTime}
 */
proto.AvailableClosingTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AvailableClosingTime;
  return proto.AvailableClosingTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AvailableClosingTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AvailableClosingTime}
 */
proto.AvailableClosingTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setListClosingTimeJstList(value);
      break;
    case 2:
      var value = new proto.DayOfLiverTerm;
      reader.readMessage(value,proto.DayOfLiverTerm.deserializeBinaryFromReader);
      msg.setDayOfLiverTerm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AvailableClosingTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AvailableClosingTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AvailableClosingTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AvailableClosingTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getListClosingTimeJstList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
  f = message.getDayOfLiverTerm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DayOfLiverTerm.serializeBinaryToWriter
    );
  }
};


/**
 * repeated int64 list_closing_time_jst = 1;
 * @return {!Array<number>}
 */
proto.AvailableClosingTime.prototype.getListClosingTimeJstList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.AvailableClosingTime} returns this
 */
proto.AvailableClosingTime.prototype.setListClosingTimeJstList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.AvailableClosingTime} returns this
 */
proto.AvailableClosingTime.prototype.addListClosingTimeJst = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.AvailableClosingTime} returns this
 */
proto.AvailableClosingTime.prototype.clearListClosingTimeJstList = function() {
  return this.setListClosingTimeJstList([]);
};


/**
 * optional DayOfLiverTerm day_of_liver_term = 2;
 * @return {?proto.DayOfLiverTerm}
 */
proto.AvailableClosingTime.prototype.getDayOfLiverTerm = function() {
  return /** @type{?proto.DayOfLiverTerm} */ (
    jspb.Message.getWrapperField(this, proto.DayOfLiverTerm, 2));
};


/**
 * @param {?proto.DayOfLiverTerm|undefined} value
 * @return {!proto.AvailableClosingTime} returns this
*/
proto.AvailableClosingTime.prototype.setDayOfLiverTerm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AvailableClosingTime} returns this
 */
proto.AvailableClosingTime.prototype.clearDayOfLiverTerm = function() {
  return this.setDayOfLiverTerm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AvailableClosingTime.prototype.hasDayOfLiverTerm = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserLiveClosingTime.prototype.toObject = function(opt_includeInstance) {
  return proto.UserLiveClosingTime.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserLiveClosingTime} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLiveClosingTime.toObject = function(includeInstance, msg) {
  var f, obj = {
    closingTimeJst: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nextClosingTimeJst: jspb.Message.getFieldWithDefault(msg, 2, 0),
    effectiveFrom: (f = msg.getEffectiveFrom()) && proto.DayOfLiverTerm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserLiveClosingTime}
 */
proto.UserLiveClosingTime.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserLiveClosingTime;
  return proto.UserLiveClosingTime.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserLiveClosingTime} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserLiveClosingTime}
 */
proto.UserLiveClosingTime.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClosingTimeJst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setNextClosingTimeJst(value);
      break;
    case 3:
      var value = new proto.DayOfLiverTerm;
      reader.readMessage(value,proto.DayOfLiverTerm.deserializeBinaryFromReader);
      msg.setEffectiveFrom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserLiveClosingTime.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserLiveClosingTime.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserLiveClosingTime} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLiveClosingTime.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getClosingTimeJst();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNextClosingTimeJst();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEffectiveFrom();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DayOfLiverTerm.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 closing_time_jst = 1;
 * @return {number}
 */
proto.UserLiveClosingTime.prototype.getClosingTimeJst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserLiveClosingTime} returns this
 */
proto.UserLiveClosingTime.prototype.setClosingTimeJst = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 next_closing_time_jst = 2;
 * @return {number}
 */
proto.UserLiveClosingTime.prototype.getNextClosingTimeJst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserLiveClosingTime} returns this
 */
proto.UserLiveClosingTime.prototype.setNextClosingTimeJst = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional DayOfLiverTerm effective_from = 3;
 * @return {?proto.DayOfLiverTerm}
 */
proto.UserLiveClosingTime.prototype.getEffectiveFrom = function() {
  return /** @type{?proto.DayOfLiverTerm} */ (
    jspb.Message.getWrapperField(this, proto.DayOfLiverTerm, 3));
};


/**
 * @param {?proto.DayOfLiverTerm|undefined} value
 * @return {!proto.UserLiveClosingTime} returns this
*/
proto.UserLiveClosingTime.prototype.setEffectiveFrom = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserLiveClosingTime} returns this
 */
proto.UserLiveClosingTime.prototype.clearEffectiveFrom = function() {
  return this.setEffectiveFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserLiveClosingTime.prototype.hasEffectiveFrom = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCup.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCup.toObject = function(includeInstance, msg) {
  var f, obj = {
    pearlCupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    termLabel: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCup}
 */
proto.PearlCup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCup;
  return proto.PearlCup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCup}
 */
proto.PearlCup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPearlCupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTermLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPearlCupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTermLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string pearl_cup_id = 1;
 * @return {string}
 */
proto.PearlCup.prototype.getPearlCupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCup} returns this
 */
proto.PearlCup.prototype.setPearlCupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.PearlCup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCup} returns this
 */
proto.PearlCup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string term_label = 3;
 * @return {string}
 */
proto.PearlCup.prototype.getTermLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCup} returns this
 */
proto.PearlCup.prototype.setTermLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCupDivision.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCupDivision.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCupDivision} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupDivision.toObject = function(includeInstance, msg) {
  var f, obj = {
    pearlCupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pearlCupDivisionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isOverallDivision: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    status: jspb.Message.getFieldWithDefault(msg, 5, 0),
    startAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCupDivision}
 */
proto.PearlCupDivision.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCupDivision;
  return proto.PearlCupDivision.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCupDivision} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCupDivision}
 */
proto.PearlCupDivision.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPearlCupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPearlCupDivisionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsOverallDivision(value);
      break;
    case 5:
      var value = /** @type {!proto.PearlCupDivisionHoldingStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCupDivision.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCupDivision.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCupDivision} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupDivision.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPearlCupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPearlCupDivisionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsOverallDivision();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getStartAt();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};


/**
 * optional string pearl_cup_id = 1;
 * @return {string}
 */
proto.PearlCupDivision.prototype.getPearlCupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setPearlCupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pearl_cup_division_id = 2;
 * @return {string}
 */
proto.PearlCupDivision.prototype.getPearlCupDivisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setPearlCupDivisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.PearlCupDivision.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_overall_division = 4;
 * @return {boolean}
 */
proto.PearlCupDivision.prototype.getIsOverallDivision = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setIsOverallDivision = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional PearlCupDivisionHoldingStatus status = 5;
 * @return {!proto.PearlCupDivisionHoldingStatus}
 */
proto.PearlCupDivision.prototype.getStatus = function() {
  return /** @type {!proto.PearlCupDivisionHoldingStatus} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.PearlCupDivisionHoldingStatus} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional int64 start_at = 6;
 * @return {number}
 */
proto.PearlCupDivision.prototype.getStartAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setStartAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 end_at = 7;
 * @return {number}
 */
proto.PearlCupDivision.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupDivision} returns this
 */
proto.PearlCupDivision.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCupDivisionWithPrize.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCupDivisionWithPrize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCupDivisionWithPrize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupDivisionWithPrize.toObject = function(includeInstance, msg) {
  var f, obj = {
    division: (f = msg.getDivision()) && proto.PearlCupDivision.toObject(includeInstance, f),
    prize: (f = msg.getPrize()) && proto.PearlCupPrize.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCupDivisionWithPrize}
 */
proto.PearlCupDivisionWithPrize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCupDivisionWithPrize;
  return proto.PearlCupDivisionWithPrize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCupDivisionWithPrize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCupDivisionWithPrize}
 */
proto.PearlCupDivisionWithPrize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.PearlCupDivision;
      reader.readMessage(value,proto.PearlCupDivision.deserializeBinaryFromReader);
      msg.setDivision(value);
      break;
    case 2:
      var value = new proto.PearlCupPrize;
      reader.readMessage(value,proto.PearlCupPrize.deserializeBinaryFromReader);
      msg.setPrize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCupDivisionWithPrize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCupDivisionWithPrize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCupDivisionWithPrize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupDivisionWithPrize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDivision();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.PearlCupDivision.serializeBinaryToWriter
    );
  }
  f = message.getPrize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.PearlCupPrize.serializeBinaryToWriter
    );
  }
};


/**
 * optional PearlCupDivision division = 1;
 * @return {?proto.PearlCupDivision}
 */
proto.PearlCupDivisionWithPrize.prototype.getDivision = function() {
  return /** @type{?proto.PearlCupDivision} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupDivision, 1));
};


/**
 * @param {?proto.PearlCupDivision|undefined} value
 * @return {!proto.PearlCupDivisionWithPrize} returns this
*/
proto.PearlCupDivisionWithPrize.prototype.setDivision = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupDivisionWithPrize} returns this
 */
proto.PearlCupDivisionWithPrize.prototype.clearDivision = function() {
  return this.setDivision(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupDivisionWithPrize.prototype.hasDivision = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PearlCupPrize prize = 2;
 * @return {?proto.PearlCupPrize}
 */
proto.PearlCupDivisionWithPrize.prototype.getPrize = function() {
  return /** @type{?proto.PearlCupPrize} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupPrize, 2));
};


/**
 * @param {?proto.PearlCupPrize|undefined} value
 * @return {!proto.PearlCupDivisionWithPrize} returns this
*/
proto.PearlCupDivisionWithPrize.prototype.setPrize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupDivisionWithPrize} returns this
 */
proto.PearlCupDivisionWithPrize.prototype.clearPrize = function() {
  return this.setPrize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupDivisionWithPrize.prototype.hasPrize = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCupPrize.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCupPrize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCupPrize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrize.toObject = function(includeInstance, msg) {
  var f, obj = {
    pearlCupId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pearlCupDivisionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    platinum: (f = msg.getPlatinum()) && proto.PearlCupPrize.Condition.toObject(includeInstance, f),
    gold: (f = msg.getGold()) && proto.PearlCupPrize.Condition.toObject(includeInstance, f),
    silver: (f = msg.getSilver()) && proto.PearlCupPrize.Condition.toObject(includeInstance, f),
    bronze: (f = msg.getBronze()) && proto.PearlCupPrize.Condition.toObject(includeInstance, f),
    spirit: (f = msg.getSpirit()) && proto.PearlCupPrize.Condition.toObject(includeInstance, f),
    rewardByOverallDivisionLiveScoreDescription: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCupPrize}
 */
proto.PearlCupPrize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCupPrize;
  return proto.PearlCupPrize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCupPrize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCupPrize}
 */
proto.PearlCupPrize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPearlCupId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPearlCupDivisionId(value);
      break;
    case 3:
      var value = new proto.PearlCupPrize.Condition;
      reader.readMessage(value,proto.PearlCupPrize.Condition.deserializeBinaryFromReader);
      msg.setPlatinum(value);
      break;
    case 4:
      var value = new proto.PearlCupPrize.Condition;
      reader.readMessage(value,proto.PearlCupPrize.Condition.deserializeBinaryFromReader);
      msg.setGold(value);
      break;
    case 5:
      var value = new proto.PearlCupPrize.Condition;
      reader.readMessage(value,proto.PearlCupPrize.Condition.deserializeBinaryFromReader);
      msg.setSilver(value);
      break;
    case 6:
      var value = new proto.PearlCupPrize.Condition;
      reader.readMessage(value,proto.PearlCupPrize.Condition.deserializeBinaryFromReader);
      msg.setBronze(value);
      break;
    case 7:
      var value = new proto.PearlCupPrize.Condition;
      reader.readMessage(value,proto.PearlCupPrize.Condition.deserializeBinaryFromReader);
      msg.setSpirit(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRewardByOverallDivisionLiveScoreDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCupPrize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCupPrize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCupPrize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPearlCupId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPearlCupDivisionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPlatinum();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.PearlCupPrize.Condition.serializeBinaryToWriter
    );
  }
  f = message.getGold();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.PearlCupPrize.Condition.serializeBinaryToWriter
    );
  }
  f = message.getSilver();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.PearlCupPrize.Condition.serializeBinaryToWriter
    );
  }
  f = message.getBronze();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.PearlCupPrize.Condition.serializeBinaryToWriter
    );
  }
  f = message.getSpirit();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.PearlCupPrize.Condition.serializeBinaryToWriter
    );
  }
  f = message.getRewardByOverallDivisionLiveScoreDescription();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.PearlCupPrize.Condition.repeatedFields_ = [3,4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCupPrize.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCupPrize.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCupPrize.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrize.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    minRank: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minLiveScore: jspb.Message.getFieldWithDefault(msg, 2, 0),
    redList: jspb.Message.toObjectList(msg.getRedList(),
    proto.PearlCupPrize.Condition.Amount.toObject, includeInstance),
    blueList: jspb.Message.toObjectList(msg.getBlueList(),
    proto.PearlCupPrize.Condition.Amount.toObject, includeInstance),
    rainbowList: jspb.Message.toObjectList(msg.getRainbowList(),
    proto.PearlCupPrize.Condition.Amount.toObject, includeInstance),
    color: jspb.Message.getFieldWithDefault(msg, 6, ""),
    minRewardByOverallDivisionLiveScore: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCupPrize.Condition;
  return proto.PearlCupPrize.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCupPrize.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinRank(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinLiveScore(value);
      break;
    case 3:
      var value = new proto.PearlCupPrize.Condition.Amount;
      reader.readMessage(value,proto.PearlCupPrize.Condition.Amount.deserializeBinaryFromReader);
      msg.addRed(value);
      break;
    case 4:
      var value = new proto.PearlCupPrize.Condition.Amount;
      reader.readMessage(value,proto.PearlCupPrize.Condition.Amount.deserializeBinaryFromReader);
      msg.addBlue(value);
      break;
    case 5:
      var value = new proto.PearlCupPrize.Condition.Amount;
      reader.readMessage(value,proto.PearlCupPrize.Condition.Amount.deserializeBinaryFromReader);
      msg.addRainbow(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMinRewardByOverallDivisionLiveScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCupPrize.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCupPrize.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCupPrize.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrize.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMinRank();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMinLiveScore();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.PearlCupPrize.Condition.Amount.serializeBinaryToWriter
    );
  }
  f = message.getBlueList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.PearlCupPrize.Condition.Amount.serializeBinaryToWriter
    );
  }
  f = message.getRainbowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.PearlCupPrize.Condition.Amount.serializeBinaryToWriter
    );
  }
  f = message.getColor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMinRewardByOverallDivisionLiveScore();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCupPrize.Condition.Amount.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCupPrize.Condition.Amount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCupPrize.Condition.Amount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrize.Condition.Amount.toObject = function(includeInstance, msg) {
  var f, obj = {
    requiredScore: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCupPrize.Condition.Amount}
 */
proto.PearlCupPrize.Condition.Amount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCupPrize.Condition.Amount;
  return proto.PearlCupPrize.Condition.Amount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCupPrize.Condition.Amount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCupPrize.Condition.Amount}
 */
proto.PearlCupPrize.Condition.Amount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRequiredScore(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCupPrize.Condition.Amount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCupPrize.Condition.Amount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCupPrize.Condition.Amount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrize.Condition.Amount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequiredScore();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 required_score = 1;
 * @return {number}
 */
proto.PearlCupPrize.Condition.Amount.prototype.getRequiredScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupPrize.Condition.Amount} returns this
 */
proto.PearlCupPrize.Condition.Amount.prototype.setRequiredScore = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 amount = 2;
 * @return {number}
 */
proto.PearlCupPrize.Condition.Amount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupPrize.Condition.Amount} returns this
 */
proto.PearlCupPrize.Condition.Amount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 min_rank = 1;
 * @return {number}
 */
proto.PearlCupPrize.Condition.prototype.getMinRank = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.setMinRank = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 min_live_score = 2;
 * @return {number}
 */
proto.PearlCupPrize.Condition.prototype.getMinLiveScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.setMinLiveScore = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Amount red = 3;
 * @return {!Array<!proto.PearlCupPrize.Condition.Amount>}
 */
proto.PearlCupPrize.Condition.prototype.getRedList = function() {
  return /** @type{!Array<!proto.PearlCupPrize.Condition.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PearlCupPrize.Condition.Amount, 3));
};


/**
 * @param {!Array<!proto.PearlCupPrize.Condition.Amount>} value
 * @return {!proto.PearlCupPrize.Condition} returns this
*/
proto.PearlCupPrize.Condition.prototype.setRedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.PearlCupPrize.Condition.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PearlCupPrize.Condition.Amount}
 */
proto.PearlCupPrize.Condition.prototype.addRed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.PearlCupPrize.Condition.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.clearRedList = function() {
  return this.setRedList([]);
};


/**
 * repeated Amount blue = 4;
 * @return {!Array<!proto.PearlCupPrize.Condition.Amount>}
 */
proto.PearlCupPrize.Condition.prototype.getBlueList = function() {
  return /** @type{!Array<!proto.PearlCupPrize.Condition.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PearlCupPrize.Condition.Amount, 4));
};


/**
 * @param {!Array<!proto.PearlCupPrize.Condition.Amount>} value
 * @return {!proto.PearlCupPrize.Condition} returns this
*/
proto.PearlCupPrize.Condition.prototype.setBlueList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.PearlCupPrize.Condition.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PearlCupPrize.Condition.Amount}
 */
proto.PearlCupPrize.Condition.prototype.addBlue = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.PearlCupPrize.Condition.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.clearBlueList = function() {
  return this.setBlueList([]);
};


/**
 * repeated Amount rainbow = 5;
 * @return {!Array<!proto.PearlCupPrize.Condition.Amount>}
 */
proto.PearlCupPrize.Condition.prototype.getRainbowList = function() {
  return /** @type{!Array<!proto.PearlCupPrize.Condition.Amount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.PearlCupPrize.Condition.Amount, 5));
};


/**
 * @param {!Array<!proto.PearlCupPrize.Condition.Amount>} value
 * @return {!proto.PearlCupPrize.Condition} returns this
*/
proto.PearlCupPrize.Condition.prototype.setRainbowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.PearlCupPrize.Condition.Amount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.PearlCupPrize.Condition.Amount}
 */
proto.PearlCupPrize.Condition.prototype.addRainbow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.PearlCupPrize.Condition.Amount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.clearRainbowList = function() {
  return this.setRainbowList([]);
};


/**
 * optional string color = 6;
 * @return {string}
 */
proto.PearlCupPrize.Condition.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.setColor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 min_reward_by_overall_division_live_score = 7;
 * @return {number}
 */
proto.PearlCupPrize.Condition.prototype.getMinRewardByOverallDivisionLiveScore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.PearlCupPrize.Condition} returns this
 */
proto.PearlCupPrize.Condition.prototype.setMinRewardByOverallDivisionLiveScore = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string pearl_cup_id = 1;
 * @return {string}
 */
proto.PearlCupPrize.prototype.getPearlCupId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.setPearlCupId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string pearl_cup_division_id = 2;
 * @return {string}
 */
proto.PearlCupPrize.prototype.getPearlCupDivisionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.setPearlCupDivisionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Condition platinum = 3;
 * @return {?proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.prototype.getPlatinum = function() {
  return /** @type{?proto.PearlCupPrize.Condition} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupPrize.Condition, 3));
};


/**
 * @param {?proto.PearlCupPrize.Condition|undefined} value
 * @return {!proto.PearlCupPrize} returns this
*/
proto.PearlCupPrize.prototype.setPlatinum = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.clearPlatinum = function() {
  return this.setPlatinum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupPrize.prototype.hasPlatinum = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Condition gold = 4;
 * @return {?proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.prototype.getGold = function() {
  return /** @type{?proto.PearlCupPrize.Condition} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupPrize.Condition, 4));
};


/**
 * @param {?proto.PearlCupPrize.Condition|undefined} value
 * @return {!proto.PearlCupPrize} returns this
*/
proto.PearlCupPrize.prototype.setGold = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.clearGold = function() {
  return this.setGold(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupPrize.prototype.hasGold = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Condition silver = 5;
 * @return {?proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.prototype.getSilver = function() {
  return /** @type{?proto.PearlCupPrize.Condition} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupPrize.Condition, 5));
};


/**
 * @param {?proto.PearlCupPrize.Condition|undefined} value
 * @return {!proto.PearlCupPrize} returns this
*/
proto.PearlCupPrize.prototype.setSilver = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.clearSilver = function() {
  return this.setSilver(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupPrize.prototype.hasSilver = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Condition bronze = 6;
 * @return {?proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.prototype.getBronze = function() {
  return /** @type{?proto.PearlCupPrize.Condition} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupPrize.Condition, 6));
};


/**
 * @param {?proto.PearlCupPrize.Condition|undefined} value
 * @return {!proto.PearlCupPrize} returns this
*/
proto.PearlCupPrize.prototype.setBronze = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.clearBronze = function() {
  return this.setBronze(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupPrize.prototype.hasBronze = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Condition spirit = 7;
 * @return {?proto.PearlCupPrize.Condition}
 */
proto.PearlCupPrize.prototype.getSpirit = function() {
  return /** @type{?proto.PearlCupPrize.Condition} */ (
    jspb.Message.getWrapperField(this, proto.PearlCupPrize.Condition, 7));
};


/**
 * @param {?proto.PearlCupPrize.Condition|undefined} value
 * @return {!proto.PearlCupPrize} returns this
*/
proto.PearlCupPrize.prototype.setSpirit = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.clearSpirit = function() {
  return this.setSpirit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PearlCupPrize.prototype.hasSpirit = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string reward_by_overall_division_live_score_description = 8;
 * @return {string}
 */
proto.PearlCupPrize.prototype.getRewardByOverallDivisionLiveScoreDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrize} returns this
 */
proto.PearlCupPrize.prototype.setRewardByOverallDivisionLiveScoreDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PearlCupPrizeItem.prototype.toObject = function(opt_includeInstance) {
  return proto.PearlCupPrizeItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PearlCupPrizeItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrizeItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    prizeItemId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    shortTitle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requiredPearl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PearlCupPrizeItem}
 */
proto.PearlCupPrizeItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PearlCupPrizeItem;
  return proto.PearlCupPrizeItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PearlCupPrizeItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PearlCupPrizeItem}
 */
proto.PearlCupPrizeItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrizeItemId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequiredPearl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PearlCupPrizeItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PearlCupPrizeItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PearlCupPrizeItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PearlCupPrizeItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrizeItemId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getShortTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequiredPearl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string prize_item_id = 1;
 * @return {string}
 */
proto.PearlCupPrizeItem.prototype.getPrizeItemId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrizeItem} returns this
 */
proto.PearlCupPrizeItem.prototype.setPrizeItemId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string short_title = 2;
 * @return {string}
 */
proto.PearlCupPrizeItem.prototype.getShortTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrizeItem} returns this
 */
proto.PearlCupPrizeItem.prototype.setShortTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string required_pearl = 3;
 * @return {string}
 */
proto.PearlCupPrizeItem.prototype.getRequiredPearl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.PearlCupPrizeItem} returns this
 */
proto.PearlCupPrizeItem.prototype.setRequiredPearl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.UserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    permitMultipleUser: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserDevice}
 */
proto.UserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserDevice;
  return proto.UserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserDevice}
 */
proto.UserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPermitMultipleUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPermitMultipleUser();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.UserDevice.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserDevice} returns this
 */
proto.UserDevice.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_id = 2;
 * @return {string}
 */
proto.UserDevice.prototype.getUserId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserDevice} returns this
 */
proto.UserDevice.prototype.setUserId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool permit_multiple_user = 3;
 * @return {boolean}
 */
proto.UserDevice.prototype.getPermitMultipleUser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserDevice} returns this
 */
proto.UserDevice.prototype.setPermitMultipleUser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    os: jspb.Message.getFieldWithDefault(msg, 2, ""),
    osVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
    platform: jspb.Message.getFieldWithDefault(msg, 4, ""),
    model: jspb.Message.getFieldWithDefault(msg, 5, ""),
    appVersion: jspb.Message.getFieldWithDefault(msg, 6, ""),
    appVersionCode: jspb.Message.getFieldWithDefault(msg, 7, ""),
    appId: jspb.Message.getFieldWithDefault(msg, 8, ""),
    timezone: jspb.Message.getFieldWithDefault(msg, 9, ""),
    language: jspb.Message.getFieldWithDefault(msg, 10, ""),
    location: jspb.Message.getFieldWithDefault(msg, 11, ""),
    registrationToken: jspb.Message.getFieldWithDefault(msg, 12, ""),
    pushNotifiable: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Device}
 */
proto.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Device;
  return proto.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Device}
 */
proto.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOs(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOsVersion(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlatform(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppVersion(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppVersionCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimezone(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocation(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationToken(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPushNotifiable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOs();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOsVersion();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPlatform();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAppVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAppVersionCode();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAppId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTimezone();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLocation();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRegistrationToken();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPushNotifiable();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.Device.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string os = 2;
 * @return {string}
 */
proto.Device.prototype.getOs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setOs = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string os_version = 3;
 * @return {string}
 */
proto.Device.prototype.getOsVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setOsVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string platform = 4;
 * @return {string}
 */
proto.Device.prototype.getPlatform = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setPlatform = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.Device.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string app_version = 6;
 * @return {string}
 */
proto.Device.prototype.getAppVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setAppVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string app_version_code = 7;
 * @return {string}
 */
proto.Device.prototype.getAppVersionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setAppVersionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string app_id = 8;
 * @return {string}
 */
proto.Device.prototype.getAppId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setAppId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string timezone = 9;
 * @return {string}
 */
proto.Device.prototype.getTimezone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setTimezone = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string language = 10;
 * @return {string}
 */
proto.Device.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string location = 11;
 * @return {string}
 */
proto.Device.prototype.getLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string registration_token = 12;
 * @return {string}
 */
proto.Device.prototype.getRegistrationToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setRegistrationToken = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool push_notifiable = 13;
 * @return {boolean}
 */
proto.Device.prototype.getPushNotifiable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.Device} returns this
 */
proto.Device.prototype.setPushNotifiable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LiverRequestedSongHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.LiverRequestedSongHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LiverRequestedSongHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiverRequestedSongHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.User.toObject(includeInstance, f),
    song: (f = msg.getSong()) && karaoke_entity_pb.Song.toObject(includeInstance, f),
    createdAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LiverRequestedSongHistory}
 */
proto.LiverRequestedSongHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LiverRequestedSongHistory;
  return proto.LiverRequestedSongHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LiverRequestedSongHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LiverRequestedSongHistory}
 */
proto.LiverRequestedSongHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.User;
      reader.readMessage(value,proto.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new karaoke_entity_pb.Song;
      reader.readMessage(value,karaoke_entity_pb.Song.deserializeBinaryFromReader);
      msg.setSong(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LiverRequestedSongHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LiverRequestedSongHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LiverRequestedSongHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LiverRequestedSongHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.User.serializeBinaryToWriter
    );
  }
  f = message.getSong();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      karaoke_entity_pb.Song.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional User user = 1;
 * @return {?proto.User}
 */
proto.LiverRequestedSongHistory.prototype.getUser = function() {
  return /** @type{?proto.User} */ (
    jspb.Message.getWrapperField(this, proto.User, 1));
};


/**
 * @param {?proto.User|undefined} value
 * @return {!proto.LiverRequestedSongHistory} returns this
*/
proto.LiverRequestedSongHistory.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LiverRequestedSongHistory} returns this
 */
proto.LiverRequestedSongHistory.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiverRequestedSongHistory.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Song song = 2;
 * @return {?proto.Song}
 */
proto.LiverRequestedSongHistory.prototype.getSong = function() {
  return /** @type{?proto.Song} */ (
    jspb.Message.getWrapperField(this, karaoke_entity_pb.Song, 2));
};


/**
 * @param {?proto.Song|undefined} value
 * @return {!proto.LiverRequestedSongHistory} returns this
*/
proto.LiverRequestedSongHistory.prototype.setSong = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.LiverRequestedSongHistory} returns this
 */
proto.LiverRequestedSongHistory.prototype.clearSong = function() {
  return this.setSong(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.LiverRequestedSongHistory.prototype.hasSong = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 created_at = 3;
 * @return {number}
 */
proto.LiverRequestedSongHistory.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.LiverRequestedSongHistory} returns this
 */
proto.LiverRequestedSongHistory.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ListenerMembershipInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ListenerMembershipInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ListenerMembershipInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListenerMembershipInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    liverId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    listenerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isMembership: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ListenerMembershipInfo}
 */
proto.ListenerMembershipInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ListenerMembershipInfo;
  return proto.ListenerMembershipInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ListenerMembershipInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ListenerMembershipInfo}
 */
proto.ListenerMembershipInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiverId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setListenerId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsMembership(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ListenerMembershipInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ListenerMembershipInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ListenerMembershipInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ListenerMembershipInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLiverId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getListenerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsMembership();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string liver_id = 1;
 * @return {string}
 */
proto.ListenerMembershipInfo.prototype.getLiverId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListenerMembershipInfo} returns this
 */
proto.ListenerMembershipInfo.prototype.setLiverId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string listener_id = 2;
 * @return {string}
 */
proto.ListenerMembershipInfo.prototype.getListenerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ListenerMembershipInfo} returns this
 */
proto.ListenerMembershipInfo.prototype.setListenerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_membership = 3;
 * @return {boolean}
 */
proto.ListenerMembershipInfo.prototype.getIsMembership = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ListenerMembershipInfo} returns this
 */
proto.ListenerMembershipInfo.prototype.setIsMembership = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Moderator.prototype.toObject = function(opt_includeInstance) {
  return proto.Moderator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Moderator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Moderator.toObject = function(includeInstance, msg) {
  var f, obj = {
    userItem: (f = msg.getUserItem()) && proto.UserItem.toObject(includeInstance, f),
    endAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    tenureCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Moderator}
 */
proto.Moderator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Moderator;
  return proto.Moderator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Moderator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Moderator}
 */
proto.Moderator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserItem;
      reader.readMessage(value,proto.UserItem.deserializeBinaryFromReader);
      msg.setUserItem(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTenureCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Moderator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Moderator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Moderator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Moderator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserItem();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserItem.serializeBinaryToWriter
    );
  }
  f = message.getEndAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTenureCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional UserItem user_item = 1;
 * @return {?proto.UserItem}
 */
proto.Moderator.prototype.getUserItem = function() {
  return /** @type{?proto.UserItem} */ (
    jspb.Message.getWrapperField(this, proto.UserItem, 1));
};


/**
 * @param {?proto.UserItem|undefined} value
 * @return {!proto.Moderator} returns this
*/
proto.Moderator.prototype.setUserItem = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Moderator} returns this
 */
proto.Moderator.prototype.clearUserItem = function() {
  return this.setUserItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Moderator.prototype.hasUserItem = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 end_at = 2;
 * @return {number}
 */
proto.Moderator.prototype.getEndAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Moderator} returns this
 */
proto.Moderator.prototype.setEndAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 tenure_count = 3;
 * @return {number}
 */
proto.Moderator.prototype.getTenureCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.Moderator} returns this
 */
proto.Moderator.prototype.setTenureCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.Gender = {
  GENDER_SECRET: 0,
  GENDER_MALE: 1,
  GENDER_FEMALE: 2,
  GENDER_HIDDEN: 3
};

/**
 * @enum {number}
 */
proto.FanType = {
  FAN_TYPE_NONE: 0,
  FAN_TYPE_ASSISTANT: 1,
  FAN_TYPE_MEMBER: 2
};

/**
 * @enum {number}
 */
proto.RankingTermType = {
  RANKING_TERM_TYPE_SURGE: 0,
  RANKING_TERM_TYPE_HOUR: 1,
  RANKING_TERM_TYPE_DAY: 2,
  RANKING_TERM_TYPE_TERM: 3,
  RANKING_TERM_TYPE_NONE: 4
};

/**
 * @enum {number}
 */
proto.NotificationType = {
  NOTIFICATION_TYPE_UNKNOWN: 0,
  NOTIFICATION_TYPE_SYSTEM: 1,
  NOTIFICATION_TYPE_OPERATOR: 2
};

/**
 * @enum {number}
 */
proto.RequestStoreReviewDisplayState = {
  REQUEST_STORE_REVIEW_DISPLAY_STATE_HIDDEN: 0,
  REQUEST_STORE_REVIEW_DISPLAY_STATE_FIRST_TIME: 1,
  REQUEST_STORE_REVIEW_DISPLAY_STATE_REPEATED: 2
};

/**
 * @enum {number}
 */
proto.PearlCupDivisionHoldingStatus = {
  PEARL_CUP_DIVISION_HOLDING_STATUS_BEFORE: 0,
  PEARL_CUP_DIVISION_HOLDING_STATUS_ONGOING: 1,
  PEARL_CUP_DIVISION_HOLDING_STATUS_CALCULATING: 2,
  PEARL_CUP_DIVISION_HOLDING_STATUS_CONFIRMED: 3
};

goog.object.extend(exports, proto);
